/*eslint-disable */
import React, { useEffect, useState } from "react"
import RightSectionUseInform from "../components/informationcomponents/useFullInform/usefulinformation"
import LeftSectionUseInform from "../components/informationcomponents/useFullInform/LeftSectionUseInfo"
import InformDocTemplate from "../components/informationcomponents/documentTemplate/doctemplateinform"
import useTranslations from "../components/useTranslations"
import SEO from "../components/seo"
import {
  FullWidthSection,
  H1Styled,
  InformationDocumentCol,
  InformationNavRow,
  InformationPageWrapper,
  InformationParagraphRow,
  InformationUsfulCol,
  InformSectionRow,
  LeftSection,
  PStyled,
  RightSection,
} from "../components/informationcomponents/informMainStyle"
import { ContainerUseful } from "../components/informationcomponents/documentTemplate/docStyle"

const Information = ({ pageContext }) => {
  const [dataUseInfo, setdataUseInfo] = useState([])
  const [leftdataUseInfo, setleftdataUseInfo] = useState([])
  const [dataDoctempInfo, setdataDoctempInfo] = useState([])
  const [openUseful, setOpenUseful] = useState(true)
  const [activeUsefulButton, setactiveUsefulButton] = useState(true)
  const [activeDocTempButton, setactiveDocTempButton] = useState(false)
  const [openDocTemp, setOpenDocTemp] = useState(false)

  const { information } = useTranslations()
  const UseInfoLeftSection = () => {
    setleftdataUseInfo([
        {
          status: true,
          data: {
            id: 0,
            title_arm: "string",
            title_ru: "string",
            title_en: "string",
            url: "string",
            status: true,
            order: 0,
            first_heading: "www.petekamutner.am",
            href: "https://www.petekamutner.am/",
            links: [
              {
                id: 1,
                label: "Բեռնել e-invoicing ծրագիրը",
                link: "https://e-invoice.taxservice.am/invoice-homepage/",
              },
              {
                id: 2,
                label:
                  "Հարկային հաշվետվությունների և վճարումների վերջնաժամկետները",
                link: "https://www.petekamutner.am/tsOS_TaxCalendar.aspx",
              },
              {
                id: 3,
                label: "Հարկերի վճարման հաշվեհամարների համառոտ ցանկը",
                link:
                  "https://www.petekamutner.am/Content.aspx?itn=tsTITaxFeesBankAccounts",
              },
              {
                id: 4,
                label: "ՊԵԿ-ի կողմից նախատեսվող ստուգումների պլանը",
                link:
                  "https://www.petekamutner.am/Content.aspx?itn=tsTIVerificationsPlan",
              },
              {
                id: 5,
                label: "Տնտեսական գործունեության տեսակների դասակարգիչներ",
                link: "https://www.petekamutner.am/tsOS_EAClassifier.aspx",
              },
              {
                id: 6,
                label: "Փնտրել հարկ վճարողներին ըստ իրենց անվանման  կամ ՀՎՀՀ-ի",
                link: "https://www.petekamutner.am/tsOS_Taxpayers.aspx",
              },
            ],
          },
        },
      ],
    )
  }
  const UseInfoRightSection = () => {
    setdataUseInfo([
      {
        status: true,
        data: {
          id: 0,
          title_arm: "string",
          title_ru: "string",
          title_en: "string",
          url: "string",
          status: true,
          order: 0,
          first_heading: "www.parliament.am",
          href: "http://www.parliament.am/",
          links: [
            {
              id: 1,
              label:
                "ՀՀ օրենքները և դրանց փոփոխությունները, ինչպես նաև նախագծեր/ամբողջությամբ ընդունվածներ բաժնում ամբողջությամբ ընդունված նախաձեռնությունները: ",
              link: "http://www.parliament.am/drafts.php?sel=approved&lang=arm",
            },
          ],
        },
      },
      {
        status: true,
        data: {
          id: 1,
          title_arm: "string",
          title_ru: "string",
          title_en: "string",
          url: "string",
          status: true,
          order: 0,
          first_heading: "www.arlis.am",
          href: "https://www.arlis.am/",
          links: [
            {
              id: 1,
              label:
                "ՀՀ աշխատանքային , քաղաքացիական, վարչական իրավախախտումների վերաբերյալ, մաքսային և մի շարք այլ օրենսգրքեր, ինչպես նաև ՀՀ օրենքներ, որոշումներ, պարզաբանումներ:",
              link: "https://www.arlis.am/",
            },
          ],
        },
      },
      {
        status: true,
        data: {
          id: 2,
          title_arm: "string",
          title_ru: "string",
          title_en: "string",
          url: "string",
          status: true,
          order: 0,
          first_heading: "www.e-gov.am",
          href: "https://www.e-gov.am/",
          links: [
            {
              id: 1,
              label:
                "Այս կայքում դուք կարող եք գտնել կառավարության ընդունած վերջին որոշումները, ինչպես նաև որոշումների արխիվը:",
              link: "https://www.e-register.am/am/",
            },
          ],
        },
      },
    ])
  }

  const test = [
    {
      status: true,
      data: {
        id: 0,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.e-request.am",
        label: "Հայաստանի Հանրապետության պետական մարմիններին առցանց դիմումներ, բողոքներ և հարցումներ ուղարկելու հարթակ:",
        links: [
          {
            id: 1,
            label:
              "Հայաստանի Հանրապետության պետական մարմիններին առցանց դիմումներ, բողոքներ և հարցումներ ուղարկելու հարթակ:",
            link: "www.e-request.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 1,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.e-register.am",
        label: "Հայաստանի Հանրապետության իրավաբանական անձանց պետական ռեգիստրի պաշտոնական կայք՝ այստեղ կարող եք իրականցնել ԱՁ և ՍՊԸ սկզբնական գրանցում, կատարել իրավաբանական անձանց որոնում, գտնել նմուշային փաստաթղթեր:",
        links: [
          {
            id: 1,
            label: "Հայաստանի Հանրապետության իրավաբանական անձանց պետական ռեգիստրի պաշտոնական կայք՝ այստեղ կարող եք իրականցնել ԱՁ և ՍՊԸ սկզբնական գրանցում, կատարել իրավաբանական անձանց որոնում, գտնել նմուշային փաստաթղթեր:",
            link: "www.e-register.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 2,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.azdarar.am",
        label: "Հրապարակային ծանուցումների պաշտոնական ինտերնետային կայք: Այստեղ կարող եք փնտրել Ձեզ ուղարկված հաղորդագրությունները, որոնք փոստի միջոցով չեն ծանուցվել Ձեզ:",
        links: [
          {
            id: 1,
            label: "Հրապարակային ծանուցումների պաշտոնական ինտերնետային կայք: Այստեղ կարող եք փնտրել Ձեզ ուղարկված հաղորդագրությունները, որոնք փոստի միջոցով չեն ծանուցվել Ձեզ:",
            link: "www.azdarar.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 3,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.datalex.am",
        label: "Դատական տեղեկատվական համակարգ՝ այստեղ կարող եք փնտրել դատական գործեր, ծանոթանալ նիստերի ժամանակացույցին և ուղարկել առցանց դիմումներ:",
        links: [
          {
            id: 1,
            label:"Դատական տեղեկատվական համակարգ՝ այստեղ կարող եք փնտրել դատական գործեր, ծանոթանալ նիստերի ժամանակացույցին և ուղարկել առցանց դիմումներ:",
            link: "www.datalex.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 4,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.e-notary.am",
        label: "Հայաստանի Հանրապետության նոտարական պալատի պաշտոնական կայք՝ այստեղ կարող եք փնտրել նոտարներ և ստանալ նոտարական գործարքի վերաբերյալ տեղեկատվություն:",
        links: [
          {
            id: 1,
            label:"Հայաստանի Հանրապետության նոտարական պալատի պաշտոնական կայք՝ այստեղ կարող եք փնտրել նոտարներ և ստանալ նոտարական գործարքի վերաբերյալ տեղեկատվություն:",
            link: "www.e-notary.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 5,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.e-cadastre.am",
        label: "Հայաստանի Հանրապետության Կադաստրի կոմիտեի էլեկտրոնային ծառայությունների մատուցման հարթակ:",
        links: [
          {
            id: 1,
            label:"Հայաստանի Հանրապետության Կադաստրի կոմիտեի էլեկտրոնային ծառայությունների մատուցման հարթակ:",
            link: "www.e-cadastre.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 6,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.cesa.am",
        label: "Հայաստանի Հանրապետության Հարկադիր կատարումն ապահովող ծառայության պաշտոնական կայք՝ այստեղ կարող եք ստանալ տեղեկատվություն կատարողական վարույթների վերաբերյալ, կատարել վճարումներ, առցանց հերթագրվել և ծանոթանալ իրականացվող հարկադիր աճուրդներին:",
        links: [
          {
            id: 1,
            label:"Հայաստանի Հանրապետության Հարկադիր կատարումն ապահովող ծառայության պաշտոնական կայք՝ այստեղ կարող եք ստանալ տեղեկատվություն կատարողական վարույթների վերաբերյալ, կատարել վճարումներ, առցանց հերթագրվել և ծանոթանալ իրականացվող հարկադիր աճուրդներին:",
            link: "www.cesa.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 7,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.e-payments.am",
        label: "Պետական վճարումների էլեկտրոնային համակարգ:",
        links: [
          {
            id: 1,
            label: "Պետական վճարումների էլեկտրոնային համակարգ:",
            link: "www.e-payments.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 8,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.e-draft.am",
        label: "Իրավական ակտերի նախագծերի հրապարակման միասնական կայք՝ այստեղ կարող եք գտնել նոր մշակվող,\t դեռ չնդունված իրավական ակտերի նախագծերը:",
        links: [
          {
            id: 1,
            label: "Իրավական ակտերի նախագծերի հրապարակման միասնական կայք՝ այստեղ կարող եք գտնել նոր մշակվող,\t դեռ չնդունված իրավական ակտերի նախագծերը:",
            link: "www.e-draft.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 9,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.gnumner.am",
        label: "ՀՀ Ֆինասների նախարարության գնումների համակարգ այստեղ կարող եք գտնել գնումների հայտարարությունների վերաբերյալ տեղեկատվություն:",
        links: [
          {
            id: 1,
            label: "ՀՀ Ֆինասների նախարարության գնումների համակարգ այստեղ կարող եք գտնել գնումների հայտարարությունների վերաբերյալ տեղեկատվություն:",
            link: "www.gnumner.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 10,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.armeps.am",
        label: "Էլեկտրոնային հարթակներում հայտարարված մրցույթներին ծանոթանլու և մասնակցելու հարթակ:",
        links: [
          {
            id: 1,
            label: "Էլեկտրոնային հարթակներում հայտարարված մրցույթներին ծանոթանլու և մասնակցելու հարթակ:",
            link: "www.armeps.am",
          },
        ],
      },
    },
    {
      status: true,
      data: {
        id: 11,
        title_arm: "string",
        title_ru: "string",
        title_en: "string",
        url: "string",
        status: true,
        order: 0,
        first_heading: "www.aaaa.am",
        label: "Հայաստանի հաշվապահների և աուդիտոների ասոցիացիայի պաշտոնական կայք: Այստեղ կարող եք ծանոթանալ պալատի նորություններին, որոկավորման ընթացակարգերին և քննական նյութերին:",
        links: [
          {
            id: 1,
            label: "Հայաստանի հաշվապահների և աուդիտոների ասոցիացիայի պաշտոնական կայք: Այստեղ կարող եք ծանոթանալ պալատի նորություններին, որոկավորման ընթացակարգերին և քննական նյութերին:",
            link: "www.aaaa.am",
          },
        ],
      },
    },
  ]
  const GetDoctempInfo = () => {
    setdataDoctempInfo(
      {
        status: true,
        data: {
          id: 0,
          title_arm: "string",
          title_ru: "string",
          title_en: "string",
          url: "string",
          status: true,
          order: 0,
          first_heading: "Փաստաթղթերի ձևանմուշներ",
          second_heading: "Այս կայքում դուք կարող եք գտնել",
          links: [
            {
              id: 0,
              label: information.documentFormats.awarenessLeaflet,
              link_word: `COVID-19 Իրազեկման թերթիկ.docx`,
            },
            {
              id: 1,
              label: information.documentFormats.thermometerResults,
              link_word: `COVID-19 Զննման արդյունքների գրանցամատյան.docx`,
            },
            {
              id: 2,
              label: information.documentFormats.disinfectionProcess,
              link_word: `COVID-19 Ախտահանման գործընթացի գրանցամատյան.docx`,
            },
            {
              id: 3,
              label: information.documentFormats.PCRExamination,
              link_exc: `COVID-19 ՊՇՌ հետազոտության գրանցամատյան.xlsx`,
            },
            {
              id: 4,
              label: information.documentFormats.vaccineStatus,
              link_exc: `COVID-19 Պատվաստումային կարգավիճակի գրանցամատյան.xlsx`,
            },
            {
              id: 5,
              label: information.documentFormats.purchaseAct,
              link_exc: `Գնման ակտ.xls`,
            },
            {
              id: 6,
              label: information.documentFormats.controlRegister,
              link_word: "Գույքագրման ակտ.docx",
            },
            {
              id: 7,
              label: information.documentFormats.tripRecord,
              link_word: "Գործուղման վկայական.docx",
            },
            {
              id: 8,
              label: information.documentFormats.travelSheet,
              link_exc: "Բեռնատար ավտոմեքենայի երթուղային թերթ.xls",
            },
            {
              id: 9,
              label: information.documentFormats.workTimeRecord,
              link_exc: "Աշխատաժամանակի հաշվարկի տեղեկագիր.xls",
            },
            {
              id: 10,
              label: information.documentFormats.cashOutputOrder,
              link_word: "Դրամարկղի ելքի օրդեր (Փաստաթուղթը արտահանված է ՀԾ հաշվապահ 6 համակարգից).docx",
            },
            {
              id: 11,
              label: information.documentFormats.powerOfAttorney,
              link_word: "Լիազորագիր.docx",
            },
            {
              id: 12,
              label: information.documentFormats.cashInputOrder,
              link_exc: "Դրամարկղի մուտքի օրդեր (Փաստաթուղթը արտահանված է ՀԾ հաշվապահ 6 համակարգից).xls",
            },
            {
              id: 13,
              label: information.documentFormats.powerToPresentToTheTaxAuthorities,
              link_word: "Լիազորագիր հարկային մարմիններ ներկայանալու համար.docx",
            },
            {
              id: 14,
              label: information.documentFormats.actOfAcceptance,
              link_word: "Հանձնման-ընդունման ակտ.docx",
            },
            {
              id: 15,
              label: information.documentFormats.announcementOfTermination,
              link_word: "Հայտարարություն գործունեության դադարեցման.docx",
            },
            {
              id: 16,
              label: information.documentFormats.serviceDeliveryAgreement,
              link_word: "Ծառայությունների մատուցման պայմանագիր.docx",
              link_pdf: "Ծառայությունների մատուցման պայմանագիր.pdf",
            },
            {
              id: 17,
              label: information.documentFormats.invoice,
              link_exc: "Հաշիվ ապրանքագիր (Փաստաթուղթը արտահանված է ՀԾ հաշվապահ 6 համակարգից).xlsx",
            },
            {
              id: 18,
              label: information.documentFormats.report,
              link_exc: "հաշվետվություն առհաշիվ գումարների ծախսման մասին.xls",
            },
            {
              id: 19,
              label: information.documentFormats.sampleFormForTaxpayers,
              link_word: "Հարկ վճարողների կողմից տեղեկություններ փակցնելու հայտարարության օրինակելի ձև.doc",
            },
            {
              id: 20,
              label: information.documentFormats.taxReferenceApplication,
              link_word: "Հարկային տեղեկանքի դիմում.doc",
            },
            {
              id: 21,
              label: information.documentFormats.actInvalidating,
              link_word: "Հարկային հաշիվը անվավեր ճանաչելու ակտ.docx",
            },
            {
              id: 22,
              label: information.documentFormats.resolution,
              link_word: "Հիմնադրի որոշում.docx",
            },
            {
              id: 23,
              label: information.documentFormats.passenger,
              link_exc: "Մարդատար ավտոմեքենայի երթուղային թերթ.xls",
            },
            {
              id: 24,
              label: information.documentFormats.resolutionOfAChange,
              link_word: "Որոշում ընկերության մասնակիցների փոփոխության.doc",
            },
            {
              id: 25,
              label: information.documentFormats.resolutionOfAChangeSole,
              link_word: "Որոշում ընկերության միակ մասնակցի փոփոխության.doc",
            },
            {
              id: 26,
              label: information.documentFormats.billOfLading,
              link_exc: "Պահանջագիր-բեռնագիր.xls",
            },
            {
              id: 27,
              label: information.documentFormats.contentSheet,
              link_word: "Պարունակության թերթիկ.doc",
            },
            {
              id: 28,
              label: information.documentFormats.paymentOrder,
              link_exc: "Վճարման հանձնարարագիր (Փաստաթուղթը արտահանված է ՀԾ հաշվապահ 6 համակարգից).xlsx",
            },
            {
              id: 29,
              label: information.documentFormats.workplaceCertificateArm,
              link_word: "Տեղեկանք աշխատանքի վայրից (հայերեն).docx",
            },
            {
              id: 30,
              label: information.documentFormats.workplaceCertificateEn,
              link_word: "Տեղեկանք աշխատանքի վայրից (անգլերեն).doc",
            },
            {
              id: 31,
              label: information.documentFormats.referenceToTheEmployer,
              link_word: "Տեղեկանք գործատուին փոխհատուցման ենթակա գումարների մասին.docx",
            },
            {
              id: 32,
              label: information.documentFormats.informationOnSubmission,
              link_exc: "ՏԵՂԵԿԱՏՎՈՒԹՅՈՒՆ Էլեկտրոնային փոստով վիճակագրական փաստաթղթերի ներկայացման.xls",
            },
          ],
        },
      },
    )
  }

  useEffect(() => {
    UseInfoRightSection()
    UseInfoLeftSection()
    GetDoctempInfo()
  }, [])

  const ChangePageUse = () => {
    setactiveUsefulButton(true)
    setactiveDocTempButton(false)
    setOpenUseful(true)
    setOpenDocTemp(false)

  }
  const ChangePageDoc = () => {
    setactiveUsefulButton(false)
    setactiveDocTempButton(true)
    setOpenDocTemp(true)
    setOpenUseful(false)
  }

  return (
    <>
      <SEO
        title={information.title}
        description={information.paragraph}
        pageContext={pageContext}
      />
      <InformationPageWrapper>
        <InformationParagraphRow>
          <H1Styled>{information.title}</H1Styled>
          <PStyled>{information.paragraph}</PStyled>
        </InformationParagraphRow>
        <InformationNavRow>
          <InformationUsfulCol
            className="submit_button"
            offset={0}
            onClick={ChangePageUse}
            open={openUseful}
            xs={24}
            active={activeUsefulButton}
          >
            {information.useInf_button}
          </InformationUsfulCol>
          <InformationDocumentCol
            className="submit_button"
            offset={0}
            onClick={ChangePageDoc}
            open={openDocTemp}
            xs={24}
            active={activeDocTempButton}
          >
            {information.doc_button}
          </InformationDocumentCol>

        </InformationNavRow>
        {openUseful ? (
          <InformSectionRow>
            <LeftSection>
              {information.usefulInformation.map((d, id) => (
                <LeftSectionUseInform usedata={d} key={id} />
              ))}
            </LeftSection>
            <RightSection>
              {information.dataUseInfo.map((d, id) => (
                <RightSectionUseInform usedata={d} key={id} />
              ))}
            </RightSection>
            <FullWidthSection style={{width:"100%"}}>
              {information.lastSectionUseInfo.map((d, id) => (
                <RightSectionUseInform usedata={d} key={id} />
              ))}
            </FullWidthSection>

          </InformSectionRow>
        ) : openDocTemp ? (
          <InformSectionRow>
            <ContainerUseful>
              {dataDoctempInfo.data.links.map((item) => (
                <InformDocTemplate usedata={item} />
              ))}
            </ContainerUseful>
          </InformSectionRow>
        ) : null}
      </InformationPageWrapper>
    </>
  )
}

export default Information
